






































import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import CustomersList from '@/components/business/customers/CustomersList.vue';
import {Getter} from 'vuex-class';
import SearchView from '@/components/SearchView.vue';
import ListGroupItem from '@/components/ListGroupItem.vue';
import CustomerContactListItem from '@/components/business/customers/CustomerContactListItem.vue';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import {profileStore} from '@/store/modules/profile';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from "vue-class-component";

@Component({
  name: 'shared-contacts',
  components: {CustomerContactListItem, ListGroupItem, SearchView, CustomersList}
})
export default class SharedContacts extends mixins(Vue, Notifications) {
    srcContacts?: any[] = [];
  
    @Getter sharedCustomers;

    get groups() {
      const {srcContacts} = this;
      return [
        {
          title: 'Customer Contacts',
          items: srcContacts
        },
        {
          title: 'Associate Contacts',
          items: []
        }
      ];
    }

    @Watch('sharedCustomers') onCustomersChanged(after, before) {
      if (!before) {
        return;
      }
      this.srcContacts = Object.assign([], after);
    }

    search(term) {
      if (!term) {
        this.srcContacts = Object.assign([], this.sharedCustomers);
        return;
      }
      const searchString = term.toLowerCase();
      const fFunc = (value) => value.fullName.toLowerCase().includes(searchString);
      this.srcContacts = this.sharedCustomers!!.filter(fFunc);
    }

    inPersonal(customer: any): boolean {
      return customer.personal && customer.personal.includes(profileStore.t2bUser!.id);
    }

    onContactSelected(contact) {
      this.$router.push({name: 'shared-customer-profile', params: {customerId: contact.id, type: 'shared'}});
    }

    async onAddToPersonalContacts(customerId) {
      await customersStore.addToPersonalContacts(customerId)
      this.showInfo('Added to My contacts')
    }

    showToast(text) {
      this.showInfo(text);
    }

    showErrorToast(error) {
      this.showIssue(error);
    }

    onError(error) {
      this.showErrorToast(error);
    }

    onInfo(message) {
      this.showToast(message);
    }

    updated() {
      console.log('SharedContacts :: updated');
    }

    mounted() {
      console.log('SharedContacts :: mounted');
    }

    created() {
      console.log('SharedContacts :: created');
      this.srcContacts = Object.assign([], this.sharedCustomers);
    };

    beforeDestroy() {
      console.log('SharedContacts :: beforeDestroy');
    }
  }
