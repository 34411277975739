






















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  name: 'list-group-item'
})
export default class ListGroupItem extends Vue {
  toggle: boolean = true;
  @Prop() items?: any[];
  @Prop() title?: string;
  @Prop() color?: any;
  @Prop({default: true}) showIcon?: boolean;
  @Prop({default: true}) chip?: boolean;

  get count() {
    return this.items && this.items.length
  }
};
